import Image from 'next/image';

export default function Logo() {
  return (
    <div className="relative w-32 h-8">
      <Image
        src="/assets/lander/logos/yodl-full.svg"
        alt="Logo"
        fill
        className="object-contain"
      />
    </div>
  );
}
